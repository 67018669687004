import React, { useState } from "react";
import { Modal } from "react-responsive-modal";
import "./TeamMember.scss";

export default function TeamMember({
  name = "",
  role = "",
  descriptionShort = "",
  description = "",
  picture = "",
  isEven = false,
  id = "",
  popup,
  popupText,
}) {
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  return (
    <>
      <div
        className={`team-member ${Boolean(isEven) && "team-member--hasOffset"}`}
      >
        <div className="team-member__picture">
          <img src={picture} alt={`${name}, ${role}}`} />
        </div>

        <div className="team-member__content">
          <h2 className="team-member__name" id={id}>
            {name}
          </h2>
          <p className="team-member__role">{role}</p>
          <p className="team-member__description">{descriptionShort}...</p>
          <button
            className="team-member__more"
            onClick={() => setOpen(true)}
            aria-label={`read more about ${name}`}
          >
            (read more)
          </button>
        </div>
      </div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        center
        focusTrapped={false}
        blockScroll={false}
        classNames={{
          overlay: "customOverlay",
          modal: "customModal",
        }}
      >
        <div className="team-member-modal">
          <img
            src={popupText}
            alt={name}
            className="team-member-modal__background"
          />

          <div className="team-member-modal__content">
            <div className="team-member-modal__overflow">
              <div className="team-member-modal__picture">
                <img src={picture} alt={`${name}, ${role}}`} />
              </div>
              <h2 className="team-member-modal__name">{name}</h2>
              <p className="team-member-modal__role">{role}</p>
              <p
                className="team-member-modal__description"
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
