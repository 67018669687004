import React from "react";

import "./JumbotronCard.scss";

export default function JumbotronCard({ type, children, ...props }) {
  return (
    <div
      className={`jumbotron-card ${type && "jumbotron-card--" + type}`}
      {...props}
    >
      {children}
    </div>
  );
}
