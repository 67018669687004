import PictureHayesBarnard from "assets/images/about-us/HayesBarnard.png";
import PictureHayesBarnardPopup from "assets/images/about-us/HayesBarnardPopup.jpg";
import PictureHayesBarnardPopupText from "assets/images/about-us/HayesBarnardPopupText.jpg";
import PictureTanguySerra from "assets/images/about-us/TanguySerra.png";
import PictureTanguySerraPopup from "assets/images/about-us/TanguySerraPopup.jpg";
import PictureTanguySerraPopupText from "assets/images/about-us/TanguySerraPopupText.jpg";
import PictureMattDawson from "assets/images/about-us/MattDawson.png";
import PictureMattDawsonPopup from "assets/images/about-us/MattDawsonPopup.jpg";
import PictureMattDawsonPopupText from "assets/images/about-us/MattDawsonPopupText.jpg";
import PictureAliceCathcart from "assets/images/about-us/AliceCathcart.png";
import PictureAliceCathcartPopup from "assets/images/about-us/AliceCathcartPopup.jpg";
import PictureAliceCathcartPopupText from "assets/images/about-us/AliceCathcartPopupText.jpg";
import PictureBrendonMerkley from "assets/images/about-us/BrendonMerkley.png";
import PictureBrendonMerkleyPopup from "assets/images/about-us/BrendonMerkleyPopup.jpg";
import PictureBrendonMerkleyPopupText from "assets/images/about-us/BrendonMerkleyPopupText.jpg";
import PictureMingWu from "assets/images/about-us/MingWu.png";
import PictureMingWuPopup from "assets/images/about-us/MingWuPopup.jpg";
import PictureMingWuPopupText from "assets/images/about-us/MingWuPopupText.jpg";
import PictureSteveLiscinsky from "assets/images/about-us/SteveLiscinsky.png";
import PictureSteveLiscinskyPopup from "assets/images/about-us/SteveLiscinskyPopup.jpg";
import PictureSteveLiscinskyPopupText from "assets/images/about-us/SteveLiscinskyPopupText.jpg";
import PictureMattMurray from "assets/images/about-us/MattMurray.png";
import PictureMattMurrayPopup from "assets/images/about-us/MattMurrayPopup.jpg";
import PictureMattMurrayPopupText from "assets/images/about-us/MattMurrayPopupText.jpg";
import PictureJasonWalker from "assets/images/about-us/JasonWalker.png";
import PictureJasonWalkerPopup from "assets/images/about-us/JasonWalkerPopup.jpg";
import PictureJasonWalkerPopupText from "assets/images/about-us/JasonWalkerPopupText.jpg";
import PictureJayLaifman from "assets/images/about-us/JayLaifman.png";
import PictureJayLaifmanPopup from "assets/images/about-us/JayLaifmanPopup.jpg";
import PictureJayLaifmanPopupText from "assets/images/about-us/JayLaifmanPopupText.jpg";
import PictureTamiTelles from "assets/images/about-us/TamiTelles.png";
import PictureTamiTellesPopup from "assets/images/about-us/TamiTellesPopup.jpg";
import PictureTamiTellesPopupText from "assets/images/about-us/TamiTellesPopupText.jpg";

export const team = [
  {
    enabled: true,
    order: 0,
    name: "Hayes Barnard",
    role: "Founder, Chairman and CEO",
    descriptionShort:
      "Hayes is a passionate entrepreneur whose career has been a testament to the power of marrying disruptive ideas with remarkable people. After almost a decade at Oracle, Hayes founded a lending business (now Loanpal) fifteen years ago, which has grown to over 1000 employees",
    description:
      "Hayes is a passionate entrepreneur whose career has been a testament to the power of marrying disruptive ideas with remarkable people.  After almost a decade at Oracle, Hayes founded a lending business (now Loanpal) fifteen years ago, which has grown to over 1000 employees and funded more than $25 billion in loans.  In addition to Loanpal being recognized as a “Best Places to Work” many times across multiple states, Hayes was the recipient of the 2010 and 2016 Global Impact Award, the Sacramento Business Journal’s 40 under 40, and a 2013 finalist for the Ernst & Young Entrepreneur of the Year.<br /><br />Hayes also founded, developed and sold Paramount Solar to SolarCity/Tesla and Paramount Equity Insurance to  Brown &amp; Brown – acquisitions that were made for hundreds of millions of dollars.<br /><br />Hayes spent three years at the publicly traded SolarCity as Chief Revenue Officer, where he grew revenues 5x and expanded the employee base from 3,000 to 17,000.<br /><br />In 2014, Hayes founded the GivePower Foundation, a non-profit using its renewable energy expertise to provide clean water, food, internet, and light to create healthier economic growth in impoverished communities around the world. GivePower has provided electricity access to more than 300,000 people in 17 countries, installed solar for light in over 2500 schools and ranger stations throughout Virunga National Park and Sarara, Kenya, protecting endangered gorillas, elephants and various other threatened wildlife.<br /><br />Hayes lives in Austin, TX with his wife Jessica and their three children.",
    picture: PictureHayesBarnard,
    popup: PictureHayesBarnardPopup,
    popupText: PictureHayesBarnardPopupText,
  },
  {
    enabled: true,
    order: 1,

    name: "Tanguy Serra",
    role: "President & Chief Investment Officer",
    descriptionShort:
      "As President & Chief Investment Officer, Tanguy leads the Loanpal innovation group, identifying new business opportunities and market growth potential by leveraging new technology, and optimizing advancements",
    description:
      "As President & Chief Investment Officer, Tanguy leads the Loanpal innovation group, identifying new business opportunities and market growth potential by leveraging new technology, and optimizing advancements in the consumer lending platform.<br /><br />Prior to Loanpal, Tanguy served as President of SolarCity – the largest residential solar company in the world, overseeing 10,000 employees. At SolarCity, he led operations alongside Hayes Barnard and helped grow the business 5x to 40% of market share (10 times the size of the nearest competitor). Additionally, this growth was achieved with the best safety and quality track record, and lowest cost structure.<br /><br />Additionally, Tanguy Co-Founded VivintSolar, a residential solar provider that would quickly become the 2nd largest in the market. Tanguy also spent 3 years in investment banking at Merrill Lynch and Morgan Stanley, London, and also served 8 years as Vice President of the private equity group at TPG in London, Singapore, Hong Kong and San Francisco.",
    picture: PictureTanguySerra,
    popup: PictureTanguySerraPopup,
    popupText: PictureTanguySerraPopupText,
  },
  {
    enabled: true,
    order: 2,

    name: "Matt Dawson",
    role: "Founder, Chief Revenue Officer",
    descriptionShort:
      "Matt is a co-founder of Loanpal. He has helped guide the strategic direction of the company from its inception, and built many areas of the business for over 14 years.",
    description:
      "Matt is a co-founder of Loanpal. He has helped guide the strategic direction of the company from its inception, and built many areas of the business for over 14 years. Matt led the launch of Loanpal’s mortgage banking platform in 2006, along with the transition of the company to a Fannie Mae and Freddie Mac seller/servicer.  He co-founded and launched Paramount Solar in 2009 and grew it into a top tier national brand until it was acquired by SolarCity in 2013. For the following 2 years, Matt served as President of Loanpal, responsible for all day-to-day operations of the company.  Concurrently, he joined Solar City as SVP of Market Development, responsible for creating their solar loan financing platform.<br /><br />In his current role as Chief Revenue Officer, Matt oversees the direction and growth of Loanpal’s consumer finance division. From home improvement to solar loans, Matt has partnered with top-tier partners to greatly expand the lending markets that Loanpal serves.",
    picture: PictureMattDawson,
    popup: PictureMattDawsonPopup,
    popupText: PictureMattDawsonPopupText,
  },
  {
    enabled: true,
    order: 3,

    name: "Alice Cathcart",
    role: "Chief Information Officer",
    descriptionShort:
      "As Chief Information Officer, Alice Cathcart is responsible for overseeing Loanpal’s robust software ecosystem, including third party and in-house custom built applications",
    description:
      "As Chief Information Officer, Alice Cathcart is responsible for overseeing Loanpal’s robust software ecosystem, including third party and in-house custom built applications. She leads product development, data & analytics, and change management for the company and partners closely with the CTO on the development and growth of Loanpal’s industry-leading consumer finance platform for investors, installers and customers.<br /><br />Prior to Loanpal, Alice recruited and led a team at SolarCity responsible for product and technical direction for SolarCity’s software systems, in addition to strategic new programs and partner and product launches. She provided operational leadership supporting SolarCity's rapid expansion into new markets. Before joining SolarCity, Alice developed large-scale solar power generation projects at SunPower Corporation.<br /><br />Alice holds a Master of Science degree in Environmental Science & Policy from Stanford University.",
    picture: PictureAliceCathcart,
    popup: PictureAliceCathcartPopup,
    popupText: PictureAliceCathcartPopupText,
  },

  {
    enabled: true,
    order: 4,
    name: "Brendon Merkley",
    role: "Chief Operating Officer",
    descriptionShort:
      "As Chief Operating Officer, Brendon manages all aspects of the customer experience and oversees the processes and operations that impact critical consumer touch points",
    description:
      "As Chief Operating Officer, Brendon manages all aspects of the customer experience and oversees the processes and operations that impact critical consumer touch points.  Brendon leads teams responsible for delivering new technology solutions across analytics, sales, operations, and servicing to ensure a lending experience that is simple, frictionless, and friendly.<br /><br />Prior to joining Loanpal, Brendon was Executive Vice President of Customer Operations & Information Systems at SolarCity where he directed teams that shaped the residential solar customer experience.  He led the enterprise information technology teams as well as the company’s global operations support centers.  He also co-founded Vivint Solar, where as COO he oversaw the fulfillment process and directed the development of various enterprise and Customer-facing software platforms.<br /><br />Brendon holds a B.S. degree in Political Science from Brigham Young University and an MBA from the Stanford Graduate School of Business.",
    picture: PictureBrendonMerkley,
    popup: PictureBrendonMerkleyPopup,
    popupText: PictureBrendonMerkleyPopupText,
  },
  {
    enabled: true,
    order: 5,

    name: "Ming Wu",
    role: "Chief Technology Officer",
    descriptionShort:
      "As Chief Technology Officer of Loanpal, Ming oversees all software engineering, technology and Business Intelligence and manages all of IT for the organization",
    description:
      "As Chief Technology Officer of Loanpal, Ming oversees all software engineering, technology and Business Intelligence and manages all of IT for the organization. With extensive experience in green technology, finance, Data &Analytics, Information Systems, sales and operations and customer support, Ming brings a wealth of management knowledge and technology experience to Loanpal.  Prior to Loanpal, Ming served as VP of Data & Analytics at Okta, where he was responsible for all corporate business intelligence and analytics; SVP of Information Systems at SolarCity where he led Software Engineering, Technical Operations, Analytics and IT. Ming also served in various management and engineering roles at DataSift, Right90, and Etrade Financial. Ming holds a B.S. degree in Mechanical Engineering from the Massachusetts Institute of Technology.",
    picture: PictureMingWu,
    popup: PictureMingWuPopup,
    popupText: PictureMingWuPopupText,
  },

  {
    enabled: true,
    order: 6,

    name: "Tami Telles",
    role: "Senior Vice President of Human Resources",
    descriptionShort:
      "As Senior Vice President of Human Resources for Loanpal, Tami is responsible for leading all areas of the Human Resources teams to include Total Rewards, and Employee Relations",
    description:
      "As Senior Vice President of Human Resources for Loanpal, Tami is responsible for leading all areas of the Human Resources teams to include Total Rewards, and Employee Relations. Tami has over 25 years of progressive experience in all areas of Human Resources.  Prior to joining Loanpal, Tami served as Vice President of Human Resources for loanDepot, Greenlight loans/Nationstar and Director of Human Resources for ditech/GMAC.<br /><br /> Tami is highly experienced in development and management of corporate human resources operations.She specializes in building and leading top performing teams, strong relationship building and effective collaboration with all levels of staff, and establishing human resources departments from ground up. Tami's primary focus at Loanpal is ensuring compliance, support business strategy, productivity, and profitability, attracting and retaining the best talent by building a culture of engagement, transparency and creativity.",
    picture: PictureTamiTelles,
    popup: PictureTamiTellesPopup,
    popupText: PictureTamiTellesPopupText,
  },

  {
    enabled: true,
    order: 7,

    name: "Jason Walker",
    role: "Founder, Chief Risk Officer",
    descriptionShort:
      "Jason is a co-founder of Loanpal, and currently serves as its Chief Risk Officer. In this role, Jason oversees Loanpal’s mortgage operations, as well as its credit policy, agency and investor relationships",
    description:
      "Jason is a co-founder of Loanpal, and currently serves as its Chief Risk Officer. In this role, Jason oversees Loanpal’s mortgage operations, as well as its credit policy, agency and investor relationships, post closing, quality control and product development.  Prior to founding Loanpal, Jason was a branch manager for National Mortgage where he oversaw sales and processing.  Jason started his mortgage career as a loan officer for American Equity.<br /><br / >A 20 year veteran of the mortgage business, Jason’s prudent decision-making has enabled Loanpal to continue to thrive by avoiding the pitfalls that other mortgage companies encountered over the last few years.<br /><br />Jason graduated from the University of Missouri with a bachelor’s degree in finance and real estate.",
    picture: PictureJasonWalker,
    popup: PictureJasonWalkerPopup,
    popupText: PictureJasonWalkerPopupText,
  },

  {
    enabled: true,
    order: 8,
    name: "Steve Liscinsky",
    role: "Chief Innovation Officer",
    descriptionShort:
      "As Chief Innovation Officer, Steve works closely with solar partners to ensure that Loanpal continues to evolve and advance its world-class partner and borrower experience",
    description:
      "As Chief Innovation Officer, Steve works closely with solar partners to ensure that Loanpal continues to evolve and advance its world-class partner and borrower experience. Steve also looks for new integration partnerships and financing opportunities that will leverage Loanpal's technology platform. In his previous role as Chief Technology Officer at Loanpal, Steve oversaw the build of a world class consumer finance platform, which proved key to helping Loanpal become the largest residential solar financing company. He and his team produced a proprietary automated underwriting system, a loan origination system, a partner portal as well as other custom software to support Loanpal's growing suite of frictionless lending options. Steve has over 20 years of success delivering business value and technology solutions across multiple industries and business functions. Prior to his current role as CIO at Loanpal, Steve served as CTO for CustomerLink Systems , COO & CTO of Latch, and VP of Product Development for SBM Site Services and was instrumental in building scalable technologies to support rapid growth.",
    picture: PictureSteveLiscinsky,
    popup: PictureSteveLiscinskyPopup,
    popupText: PictureSteveLiscinskyPopupText,
  },

  {
    enabled: true,
    order: 9,
    name: "Matt Murray",
    role: "Chief Marketing Officer",
    descriptionShort:
      "As Chief Marketing Officer of Loanpal, Matt oversees all marketing efforts, as well as its customer acquisition channels including radio, direct mail, digital marketing and affiliate partnerships",
    description:
      "As Chief Marketing Officer of Loanpal, Matt oversees all marketing efforts, as well as its customer acquisition channels including radio, direct mail, digital marketing and affiliate partnerships. Matt also manages the Loanpal call centers.<br /><br />Prior to joining Loanpal, Matt served as the Senior Vice President of Marketing for loanDepot, where he built a robust customer acquisition engine with an annualized budget of over $50MM. Before that, he joined E-Loan as its 7th employee, where he spent 7 years as an executive changing the way consumers interact with mortgage companies. Matt has also held Senior Marketing roles with Merrill Lynch Bank & Trust, Accredited Home Lenders, National City Bank and Realtor.com.  He currently holds a mortgage originator license NMLS #1102210.",
    picture: PictureMattMurray,
    popup: PictureMattMurrayPopup,
    popupText: PictureMattMurrayPopupText,
  },

  {
    enabled: true,
    order: 10,
    name: "Jay Laifman",
    role: "General Counsel and Chief Compliance Officer",
    descriptionShort:
      "As General Counsel and Chief Compliance Officer, Jay oversees all legal, regulatory, licensing, compliance and corporate governance matters, ensuring that Loanpal develops and maintains the necessary",
    description:
      "As General Counsel and Chief Compliance Officer, Jay oversees all legal, regulatory, licensing, compliance and corporate governance matters, ensuring that Loanpal develops and maintains the necessary oversight and protection for its customers, employees and investors.<br /><br />Prior to Loanpal, Jay was Head of Consumer Compliance at SolarCity Corporation, where he oversaw development and management of the compliance program for consumer product sales, leasing, lending and servicing. Before he joined SolarCity, he was the Executive Vice President and Deputy General Counsel at a Fortune 250 mortgage lender for over a decade.<br /><br />Jay holds a JD degree from Hastings College of the Law and a BA with double majors in Computer and Information Sciences and Legal Studies from the University of California, Santa Cruz.",
    picture: PictureJayLaifman,
    popup: PictureJayLaifmanPopup,
    popupText: PictureJayLaifmanPopupText,
  },
];

export default { team };
