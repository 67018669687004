import React from "react";
import Main from "components/layout/Main";
import HeaderSkew from "components/HeaderSkew";
import Block from "components/Block";
import BlockTitle from "components/BlockTitle";
import JumbotronCard from "components/JumbotronCard";
import TeamMember from "components/TeamMember";
import ParagraphPointer from "components/ParagraphPointer";
import ScrollToTop from "components/ScrollToTop";

import backgroundCover from "assets/images/header_skew/header-about_us.jpg";

import { team } from "data/page-about-us";

export default function AboutUsPage() {
  return (
    <Main page={"about-us"} pagetitle="About Us">
      <HeaderSkew background={backgroundCover}>
        <JumbotronCard type="in-header">
          <div className="mobile-only">
            <h1>About Us</h1>
            <p>
              We are the conduit to a sustainable home solutions marketplace,
              providing frictionless, point-of-sale technology for thousands of
              mission-driven professionals, and millions of homeowners who want
              to upgrade their homes and save money.
            </p>
          </div>
          <div className="desktop-only">
            <h1>About Us</h1>
            <p>
              We are the conduit to a sustainable home solutions marketplace,
              providing frictionless, point-of-sale technology for thousands of
              mission-driven professionals, and millions of homeowners who want
              to upgrade their homes and save money.
            </p>
            <p>
              We remain committed to caring for the planet, building lasting
              relationships with our valued partners and customers, and
              delivering cutting-edge technology that empowers millions to
              embrace a more sustainable lifestyle.
            </p>
          </div>
        </JumbotronCard>
      </HeaderSkew>
      <Block
        layer="1"
        name="About Us"
        guides={2}
        guideType="center"
        appearance="grid-single"
      >
        <div className="guides-center">
          {team
            .filter(({ enabled }) => enabled)
            .map((m, index) => (
              <ParagraphPointer
                key={`team-leader-pointer-${index}`}
                linkedTo={`#tm${index}`}
              />
            ))}
        </div>
        <BlockTitle title="Leadership Team" />
        {team
          .filter(({ enabled }) => enabled)
          .map((member, index) => (
            <TeamMember
              key={`team-leader-${index}`}
              {...member}
              isEven={index % 2}
              id={`tm${index}`}
            />
          ))}

        <ScrollToTop modifier={["slim-bottom"]} guides={0} />
      </Block>
    </Main>
  );
}
